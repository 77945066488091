import http from "../utils/http";
import { stringify } from 'qs';

export function jobsList(params) {
    return http.get("/zJob/jobsList?" + stringify(params));
}

export function jobsDel(params) {
    return http.get("/zJob/update?" + stringify(params));
}

export function jobsUpdate(params) {
    return http.post("/zJob/add?" + stringify(params));
}
