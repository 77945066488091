<template>
  <div class="post">
    <div class="content">
        <el-row>
            <el-col>
                <el-button type="primary" @click="updateVisible = true;dialogTitle = '新增';">新增</el-button>
            </el-col>
        </el-row>
        <div class="tableBox">
            <el-table
                :data="tableData"
                v-loading="tableLoading"
                max-height="650"
                border
                style="width: 100%">
                <el-table-column
                    prop="createAt"
                    label="创建时间"
                    width="160">
                </el-table-column>
                <el-table-column
                    prop="city"
                    label="城市"
                    width="60">
                </el-table-column>
                <el-table-column
                    prop="company"
                    label="公司"
                    min-width="260">
                </el-table-column>
                <el-table-column
                    prop="companyAddreess"
                    label="公司地址"
                    min-width="200">
                </el-table-column>
                <el-table-column
                    prop="companyAddreess"
                    label="工作地点"
                    min-width="200">
                </el-table-column>
                <el-table-column
                    prop="jobs"
                    label="岗位"
                    width="150">
                </el-table-column>
                <el-table-column
                    prop="type"
                    label="岗位类型"
                    width="60">
                    <template slot-scope="scope">
                        <span>{{ formatType(scope.row.type) }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="jobsContent"
                    label="工作内容"
                    min-width="500">
                </el-table-column>
                <el-table-column
                    prop="salaryRange"
                    label="薪资范围"
                    width="150">
                </el-table-column>
                <el-table-column
                    prop="salaryStructure"
                    label="薪资构成"
                    width="100">
                </el-table-column>
                <el-table-column
                    prop="startTime"
                    label="开始日期"
                    width="100"
                    :formatter="dateFormat">
                </el-table-column>
                <el-table-column
                    prop="endTime"
                    label="结束日期"
                    width="100"
                    :formatter="dateFormat">
                </el-table-column>
                <el-table-column
                    prop="status"
                    label="状态"
                    width="100">
                    <template slot-scope="scope">
                        <el-tag
                            :type="scope.row.status === 1 ? 'primary' : 'danger'"
                            disable-transitions>{{scope.row.status === 1 ? '正常' : '删除'}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    label="操作"
                    fixed="right"
                    width="150" >
                    <template slot-scope="scope">
                        <el-button v-auth="['超级管理员']"
                        size="small"
                        @click.native.prevent="editRow(scope.$index, tableData)"
                        type="primary">
                        编辑
                        </el-button>
                        <el-button v-auth="['超级管理员']"
                                   size="small"
                        @click.native.prevent="deleteRow(scope.$index, tableData)"
                        type="primary">
                        删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="paginationBox">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            background
            layout="total, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
        <el-dialog
            :title="dialogTitle"
            :visible.sync="updateVisible"
            :before-close="beforeClose"
            width="30%">
            <el-form 
                ref="form"
                :model="form"
                :rules="rules"
                label-width="90px">
                <el-form-item label="城市" prop="city">
                    <el-input placeholder="请输入城市" v-model.trim="form.city"></el-input>
                </el-form-item>
                <el-form-item label="岗位" prop="jobs">
                    <el-input placeholder="请输入岗位" v-model.trim="form.jobs"></el-input>
                </el-form-item>
                <el-form-item label="公司" prop="company">
                    <el-input placeholder="请输入公司" v-model.trim="form.company"></el-input>
                </el-form-item>
                <el-form-item label="薪资范围" prop="salaryRange">
                    <el-input placeholder="请输入薪资范围" v-model.trim="form.salaryRange"></el-input>
                </el-form-item>
                <el-form-item label="工作内容" prop="jobsContent">
                    <el-input type="textarea" :rows="4" placeholder="请输入工作内容" v-model.trim="form.jobsContent"></el-input>
                    <p style="font-size: 12px;line-height: 1.5;color: rgb(54 74 147);">多项要求;分隔输入</p>
                </el-form-item>
                <el-form-item label="工作地点" prop="companyAddreess">
                    <el-input placeholder="请输入工作地点" v-model.trim="form.companyAddreess"></el-input>
                </el-form-item>
                <el-form-item label="薪资结构" prop="salaryStructure">
                    <el-input placeholder="请输入薪资结构" v-model.trim="form.salaryStructure"></el-input>
                </el-form-item>
                <el-form-item label="工作要求" prop="jobsType">
                    <el-input placeholder="请输入工作要求" v-model.trim="form.jobsType"></el-input>
                    <p style="font-size: 12px;line-height: 1.5;color: rgb(54 74 147);">多项要求逗号分隔输入</p>
                </el-form-item>
                <el-form-item label="开始时间" prop="startTime">
                    <el-date-picker
                        v-model.trim="form.startTime"
                        type="date"
                        value-format="yyyy-MM-dd"
                        style="width: 100%;"
                        placeholder="选择开始日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间" prop="endTime">
                    <el-date-picker
                        v-model.trim="form.endTime"
                        type="date"
                        value-format="yyyy-MM-dd"
                        style="width: 100%;"
                        placeholder="选择结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="类型" prop="type">
                    <el-select v-model="form.type" placeholder="请选择" style="width: 100%">
                        <el-option
                            v-for="item in typeList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="beforeClose()">取 消</el-button>
                <el-button type="primary" @click="handleConfirm()">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="删除"
            :visible.sync="delVisible"
            :before-close="beforeClose"
            width="30%">
            <span>是否删除岗位?</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="delVisible = false; itemId = -1">取 消</el-button>
                <el-button type="primary" @click="delVisible = false; jobsDel(itemId)">确 定</el-button>
            </span>
        </el-dialog>
    </div>
  </div>
</template>

<script>
import { jobsList, jobsDel, jobsUpdate } from '../../api/post';
import jobType from '../../config/jobType.json';
import moment from "moment"

export default {
    data() {
        return {
            itemId: -1,
            currentPage: 1,
            pageSize: 10,
            total: 0,
            tableData: [],
            tableLoading: true,
            updateVisible: false,
            delVisible: false,
            dialogTitle: '',
            typeList: jobType.list,
            form: {
                city: '',
                jobs: '',
                company: '',
                salaryRange: '',
                jobsContent: '',
                companyAddreess: '',
                salaryStructure: '',
                jobsType: '',
                type: '',
                startTime: '',
                endTime: '',
            },
            rules: {
                city: [
                    { required: true, message: '请输入城市', trigger: 'blur' },
                ],
                jobs: [
                    { required: true, message: '请输入岗位', trigger: 'blur' },
                ],
                company: [
                    { required: true, message: '请输入公司', trigger: 'blur' },
                ],
                salaryRange: [
                    { required: true, message: '请输入薪资范围', trigger: 'blur' },
                ],
                jobsContent: [
                    { required: true, message: '请输入工作内容', trigger: 'blur' },
                ],
                companyAddreess: [
                    { required: true, message: '请输入工作地点', trigger: 'blur' },
                ],
                salaryStructure: [
                    { required: true, message: '请输入薪资结构', trigger: 'blur' },
                ],
                jobsType: [
                    { required: true, message: '请输入工作要求', trigger: 'blur' },
                ],
                type: [
                    { required: true, message: '请选择类型', trigger: 'change' },
                ],
                startTime: [
                    { required: true, message: '请选择开始日期', trigger: 'change' },
                ],
                endTime: [
                    { required: true, message: '请选择结束日期', trigger: 'change' },
                ]
            },
        }
    },
    mounted() {
        /**
         * @author XU
         */
        this.jobsList();
    },
    methods: {
        async jobsList() {
            try {
                const params = {
                    page: this.currentPage,
                    limit: this.pageSize
                };
                this.tableLoading = true;
                let data = await jobsList(params);
                if (data.zJobsList) {
                    this.tableData = data.zJobsList;
                    this.total = data.total;
                }
            } catch (error) {
                console.log("error:", error)
            }
            this.tableLoading = false;
        },
        async jobsUpdate() {
            try {
                const params = {
                    ...this.form
                };
                if (this.itemId != -1 && this.dialogTitle === '编辑') {
                    params.id = this.itemId;
                }
                await jobsUpdate(params);
                this.jobsList();
            } catch (error) {
                console.log("error:", error)
            }
            this.beforeClose();
         },
        async jobsDel(id) {
            try {
                const params = {
                    id
                };
                await jobsDel(params);
                this.jobsList();
            } catch (error) {
                console.log("error:", error)
            }
        },
        handleSizeChange(size) {
            this.pageSize = size;
        },
        handleCurrentChange(current) {
            this.currentPage = current;
            this.jobsList();
        },
        editRow(index, list) {
            this.dialogTitle = "编辑";
            this.updateVisible = true;
            for (let key in this.form) {
                this.form[key] = list[index][key] + "";
            }
            this.form.endTime = moment(this.form.endTime).format("YYYY-MM-DD")
            this.form.startTime = moment(this.form.startTime).format("YYYY-MM-DD")
            this.itemId = list[index].id;
        },
        deleteRow(index, list) {
            this.delVisible = true;
            this.itemId = list[index].id;
        },
        beforeClose() {
            this.updateVisible = false;
            this.$refs['form'].resetFields();
        },
        handleConfirm() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.jobsUpdate();
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        formatType(type) {
            let result = ""
            switch (type + "") {
                case "1":
                    result = "骑手";
                    break;
                case "2":
                    result = "服务员";
                    break;
                default:
                break;
            }
            return result;
        },
        /*日期处理*/
        dateFormat:function(row,column){
                var date = row[column.property];
                if(date === undefined){
                return ''
                }
                return moment(date).format("YYYY-MM-DD")
                },
        }
}
</script>

<style lang="scss" scoped>
.post {
    .content{
        .tableBox {
            padding-top: 20px;
        }
        .paginationBox {
            padding-top: 20px;
            text-align: center;
        }
    }
}
</style>